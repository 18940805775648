import Box from "@mui/material/Box"
import Ellipsis from "./ellipsisAnim"

export default function Loading() {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Ellipsis />
    </Box>
  )
}
