import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"
import Button from "@mui/material/Button"

const LogoutButton = props => (
  <Button
    color="secondary"
    variant="outlined"
    startIcon={<LogoutOutlinedIcon />}
    sx={{
      textTransform: "none",
      fontWeight: "normal",
      mb: 2,
      borderRadius: 8,
      py: 1,
      px: 3,
      borderColor: "secondary.main",
      backgroundColor: "transparent",
      transition: "all 0.3s ease",
      ...props.sx,
      "&:hover": {
        backgroundColor: "rgba(255, 240, 228, 0.08)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
        borderColor: "secondary.dark"
      },
      "&:active": {
        backgroundColor: "rgba(255, 240, 228, 0.2)",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)"
      }
    }}
    {...props}
  >
    Sign out
  </Button>
)

export default LogoutButton
