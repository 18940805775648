import HomeIcon from "@mui/icons-material/HomeOutlined"
import HomeFilledIcon from "@mui/icons-material/Home"
import SampleIcon from "@mui/icons-material/InsertDriveFileOutlined"
import SampleFilledIcon from "@mui/icons-material/InsertDriveFile"
import PaymentIcon from "@mui/icons-material/PaymentRounded"
import PaymentFilledIcon from "@mui/icons-material/PaymentTwoTone"
import ProfileIcon from "@mui/icons-material/PersonOutlineRounded"
import ProfileFilledIcon from "@mui/icons-material/Person"
import SettingsIcon from "@mui/icons-material/SettingsOutlined"
import SettingsFilledIcon from "@mui/icons-material/SettingsRounded"

const tabRules = {
  Dashboard: {
    path: "/",
    end: true,
    icon: {
      default: <HomeIcon />,
      selected: <HomeFilledIcon />
    },
    disabled: false
  },
  Profile: {
    path: "/profile",
    end: true,
    icon: {
      default: <ProfileIcon />,
      selected: <ProfileFilledIcon />
    },
    disabled: false
  },
  "Sample Papers": {
    path: "/sample",
    end: false,
    icon: {
      default: <SampleIcon />,
      selected: <SampleFilledIcon />
    },
    disabled: ["profile", "email", "payment"]
  },
  Payment: {
    path: "/payment",
    end: true,
    icon: {
      default: <PaymentIcon />,
      selected: <PaymentFilledIcon />
    },
    disabled: ["profile", "email"]
  },
  // Query: {
  //   path: "/query",
  //   end: false,
  //   icon: {
  //     default: <QueryIcon />,
  //     selected: <QueryIcon />
  //   },
  //   disabled: false
  // },
  Settings: {
    path: "/settings",
    end: true,
    icon: {
      default: <SettingsIcon />,
      selected: <SettingsFilledIcon />
    },
    disabled: false
  }
}

export default tabRules
