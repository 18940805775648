import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router-dom"
import legalLinks from "./legalLinks.json"

export default function Legal({ sx }) {
  return (
    <Box sx={{ mb: { xs: 18, sm: 1 }, mt: 2 }}>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ mb: 1.5, mt: 8, color: "grey.400", ...sx }}
      >
        <Link
          variant="body1"
          underline="hover"
          sx={{ color: "inherit" }}
          href={`${process.env.REACT_APP_HOME_URL}/contact`}
        >
          Contact
        </Link>
        {legalLinks.map((set, index) => (
          <Link
            key={index}
            component={RouterLink}
            variant="body1"
            underline="hover"
            sx={{ color: "inherit" }}
            to={set.link}
          >
            {set.label}
          </Link>
        ))}
      </Stack>
      <Typography
        sx={{ color: "grey.600", mb: 1 }}
        component="p"
        align="center"
        variant="caption"
      >
        &copy; {new Date().getFullYear()}{" "}
        <Link
          underline="hover"
          href={`${process.env.REACT_APP_HOME_URL}/resources/team`}
          sx={{ color: "inherit" }}
        >
          LIMIT Team
        </Link>{" "}
        All Rights Reserved
      </Typography>
    </Box>
  )
}
