import { useEffect } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import Loading from "./loading"

export default function ProtectedRoute({ user, auth }) {
  const location = useLocation()

  useEffect(() => {
    auth()
    const id = setInterval(auth, 60000)
    return () => clearInterval(id) // eslint-disable-next-line
  }, [location.pathname])

  if (user === undefined) {
    return <Loading />
  } else if (user === null) {
    return <Navigate to="login" replace state={{ next: location.pathname }} />
  }
  return <Outlet />
}
