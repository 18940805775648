import React from "react"
import ReactDOM from "react-dom/client"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./theme"
import "./index.css"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("limit"))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
)
