import createTheme from "@mui/material/styles/createTheme"
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes"

var theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      dark: "#004d4f",
      main: "#066c6e",
      light: "#32b2b5",
      contrastText: "#E1E9D1"
    },
    secondary: {
      dark: "#5d3a2a",
      main: "#8a5b4c",
      light: "#b88572",
      contrastText: "#FFF0E4"
    },
    background: {
      cream: "#f5f0ea",
      darkCream: "#E4D8C8",
      alt: "#f6f6e9",
      sponsor: "#e9ddd8",
      dark: "#000"
    }
  },
  typography: {
    h1: {
      fontSize: "3.5rem",
      fontWeight: 400
    },
    h2: {
      fontSize: "2.8rem",
      fontWeight: 300
    },
    h3: {
      fontSize: "2.1rem",
      fontWeight: 300
    },
    h4: {
      fontSize: "1.7rem",
      fontWeight: 200
    }
  },
  transitions: {
    easing: {
      nav: "cubic-bezier(0.2, 0.94, 0.36, 1)"
    },
    duration: {
      navIn: 1300,
      navOut: 500
    }
  },
  shape: {
    borderRadius: 5
  },
  components: {
    MuiButton: {
      defaultProps: {
        sx: {
          textTransform: "none",
          fontWeight: "normal",
          borderRadius: 20,
          py: 1,
          px: 4
        }
      }
    }
  }
})

theme = responsiveFontSizes(theme, {
  breakpoints: ["sm", "md"],
  factor: 3
})

export default theme
