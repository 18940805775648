import Paper from "@mui/material/Paper"
import styled from "@mui/material/styles/styled"

const BottomNavBar = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  position: "fixed",
  bottom: 16,
  left: 16,
  right: 12,
  zIndex: 1000,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  backdropFilter: "blur(10px)",
  backgroundColor: theme.palette.background.paper
}))

export default BottomNavBar
