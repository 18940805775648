import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import styled from "@mui/material/styles/styled"
import Nav from "./nav"
import Legal from "../../components/legal"

const Back = styled(Box)(({ theme }) => ({
  background: theme.palette.common.black,
  [theme.breakpoints.up("sm")]: {
    background: `linear-gradient(to right, ${theme.palette.background.paper} 50%, ${theme.palette.common.black} 50%)`
  }
}))

const Main = styled(props => <Box component="main" {...props} />)(
  ({ theme }) => ({
    flexGrow: 1,
    boxSizing: "border-box",
    minHeight: "100vh",
    paddingTop: theme.spacing(2),
    borderLeft: "none",
    color: theme.palette.grey[300],
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.up("sm")]: {
      minHeight: "100vh",
      paddingTop: theme.spacing(4),
      borderLeft: `1px solid ${theme.palette.grey[800]}`
    }
  })
)

export default function AccountLayout({
  user,
  logout,
  fetchProfile,
  statusUpdater
}) {
  useEffect(statusUpdater, [])
  useEffect(fetchProfile, [])

  return (
    <Back>
      <Container maxWidth="lg" sx={{ display: "flex", alignItems: "start" }}>
        <Nav steps={user?.steps} img={user?.imgUrl} logout={logout} />
        <Main>
          <Outlet />
          <Legal />
        </Main>
      </Container>
    </Back>
  )
}
