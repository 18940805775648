import BottomNavigationAction from "@mui/material/BottomNavigationAction"
import styled from "@mui/material/styles/styled"

const BottomNavButton = styled(BottomNavigationAction)(
  ({ theme, selected }) => ({
    color: selected
      ? theme.palette.primary.contrastText
      : theme.palette.text.secondary,
    minWidth: 0,
    maxWidth: "none",
    flexDirection: "column",
    padding: theme.spacing(1),
    margin: theme.spacing(0, 1),
    borderRadius: theme.shape.borderRadius * 2,
    "& .MuiBottomNavigationAction-label": {
      color: selected
        ? theme.palette.primary.contrastText
        : theme.palette.text.secondary
    },
    "& .MuiSvgIcon-root": {
      backgroundColor: selected ? theme.palette.action.hover : "transparent",
      color: selected
        ? theme.palette.primary.contrastText
        : theme.palette.text.secondary,
      borderRadius: theme.shape.borderRadius * 10,
      padding: theme.spacing(0.4, 2),
      fontSize: theme.typography.h3.fontSize,
      transition: "all 0.3s ease"
    },
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
      pointerEvents: "none"
    }
  })
)

export default BottomNavButton
