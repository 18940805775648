import Box from "@mui/material/Box"

const ProfilePicture = ({ src, width = "35%", ...props }) => (
  <Box
    component="img"
    src={src}
    referrerPolicy="no-referrer"
    sx={{ width: width, borderRadius: "50%", mb: 2 }}
    {...props}
  />
)

export default ProfilePicture
